import React, { useState } from "react";
import BusinessOrg from "components/our-customers/businessOrg";
import LawFinance from "./lawFinance";
import HighTech from "./highTech";
import Academy from "components/our-customers/academy";
import Apps from "./apps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import All from "./All";
import GetInTouch from "components/getInTouch";

function Customers() {
  const [linkOne, setLinkOne] = useState(true);
  const [linkTwo, setLinkTwo] = useState(false);
  const [linkThree, setLinkThree] = useState(false);
  const [linkFour, setLinkFour] = useState(false);
  const [linkFive, setLinkFive] = useState(false);
  const [linkSix, setLinkSix] = useState(false);

  const handleLinkOne = () => {
    setLinkOne(true);
    if (linkTwo) {
      setLinkTwo(false);
    }

    if (linkThree) {
      setLinkThree(false);
    }
    if (linkFour) {
      setLinkFour(false);
    }
    if (linkFive) {
      setLinkFive(false);
    }
    if (linkSix) {
      setLinkSix(false);
    }
  };
  const handleLinkTwo = () => {
    setLinkTwo(true);
    if (linkOne) {
      setLinkOne(false);
    }
    if (linkThree) {
      setLinkThree(false);
    }
    if (linkFour) {
      setLinkFour(false);
    }
    if (linkFive) {
      setLinkFive(false);
    }
    if (linkSix) {
      setLinkSix(false);
    }
  };
  const handleLinkThree = () => {
    setLinkThree(true);

    if (linkOne) {
      setLinkOne(false);
    }
    if (linkTwo) {
      setLinkTwo(false);
    }
    if (linkFour) {
      setLinkFour(false);
    }
    if (linkFive) {
      setLinkFive(false);
    }
    if (linkSix) {
      setLinkSix(false);
    }
  };
  const handleLinkFour = () => {
    setLinkFour(true);
    if (linkOne) {
      setLinkOne(false);
    }
    if (linkTwo) {
      setLinkTwo(false);
    }
    if (linkThree) {
      setLinkThree(false);
    }
    if (linkFive) {
      setLinkFive(false);
    }
    if (linkSix) {
      setLinkSix(false);
    }
  };
  const handleLinkFive = () => {
    setLinkFive(true);
    if (linkOne) {
      setLinkOne(false);
    }
    if (linkTwo) {
      setLinkTwo(false);
    }
    if (linkThree) {
      setLinkThree(false);
    }
    if (linkFour) {
      setLinkFour(false);
    }
    if (linkSix) {
      setLinkSix(false);
    }
  };

  const handleLinkSix = () => {
    setLinkSix(true);
    if (linkOne) {
      setLinkOne(false);
    }
    if (linkTwo) {
      setLinkTwo(false);
    }
    if (linkThree) {
      setLinkThree(false);
    }
    if (linkFour) {
      setLinkFour(false);
    }
    if (linkFive) {
      setLinkFive(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="w-full our-customer-bg  ">
          <div className="w-full max-w-7xl p-4 block m-auto text-[#012458] py-6">
            <div className="flex items-center gap-2">
              <Link to="/">
                <img
                  src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                  alt="home"
                />
              </Link>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="w-3 h-3 text-[#494949]"
              />
              <p className="text-[#494949]">Client List</p>
            </div>
            <div className="my-6 mt-12">
              <h1 className="text-5xl font-bold text-left px-4 sm:px-0 md:p-0 lg:px-0 font-primary mb-4">
                Our Clients
              </h1>
              <p className="px-4 sm:px-0 md:px-0 lg:px-0 leading-8 font-opensans lg:w-1/2">
                Tomedes has provided quality translation services to local and
                international businesses for more than a decade. We understand
                their specific needs and help them to achieve their goals.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-ourCustomerBg bg-cover bg-no-repeat">
          <ul className="lg:flex text-center w-ful max-w-6xl  justify-center sm:pt-12 py-8 p-4  pb-0 m-auto">
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkOne && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkOne}
              onKeyDown={handleLinkOne}
            >
              Businesses and Organizations
            </li>
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkTwo && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkTwo}
              onKeyDown={handleLinkTwo}
            >
              High Tech & Internet
            </li>
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkThree && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkThree}
              onKeyDown={handleLinkThree}
            >
              Law & Finance
            </li>
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkFour && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkFour}
              onKeyDown={handleLinkFour}
            >
              Academy
            </li>
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkFive && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkFive}
              onKeyDown={handleLinkFive}
            >
              Apps
            </li>
            <li
              className={`text-xl px-2 py-4 cursor-pointer relative 2xl:px-6 xl:px-5 ${
                linkSix && "our-customer-tab our-customer-tab-bottom"
              }`}
              onClick={handleLinkSix}
              onKeyDown={handleLinkSix}
            >
              All
            </li>
          </ul>
          <div className="grid grid-cols-1 w-full max-w-7xl mx-auto p-4 place-items-center">
            {/* business and organizations */}

            {linkOne ? <BusinessOrg /> : null}
            {/* HinghTech and internet */}
            {linkTwo ? <HighTech /> : null}

            {/* Law And Finance */}
            {linkThree ? <LawFinance /> : null}
            {/* Academy */}
            {linkFour ? <Academy /> : null}
            {/* Apps */}

            {linkFive ? <Apps /> : null}
            {linkSix ? <All /> : null}
          </div>
        </div>
        <GetInTouch />
      </div>
    </>
  );
}

export default Customers;
