import React from "react";
import Customers from "components/our-customers/index";
import Layout from "components/layout";
import SEO from "components/seo";

function ourCustomers() {
  const description =
    "Learn how companies and organizations around the world use Tomedes for their translation and localization needs.";
  const title = "Our Customers | Tomedes Translation";
  const keywords = "our customer";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/our-customers"
      />
      <Customers />
    </Layout>
  );
}

export default ourCustomers;
